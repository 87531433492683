.categoryDetailSubCat {
  height: calc(100vh - 340px);
}

.categoryDetailImageConatiner {
  height: calc(100vh - 460px);
}

.bg-light-blue {
  background-color: #F1F9F3 !important;
}