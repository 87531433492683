* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


.main-section {
    display: flex;
    width: 100%;
    height: 100vh;
}

.section-left {
    background-color: #ffffff;
    width: 22%;
}

.section-right {
    background-color: #f7f7f2;
    width: 78%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 3%;
}

.path-section {
    height: 10%;
}

.child-section {
    height: 88%;
}

.caterogyPageScroll {
    height: calc(100vh - 335px);
}

.categoryImageDiv {
    height: 50px !important;
    width: 50px !important;
    background-color: #F1F9F3 !important;
}

.categoryImage {
    width: 40px;
    object-fit: contain !important;
}

#vehicle1 {
    width: 23px;
    height: 23px;
}

.imgbet {
    width: 51px;
    height: 51px;
}

.checker {
    width: 23px;
    height: 23px;
}

.addbtn {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;

    width: 79px;
    height: 30px;



    border: 1px solid #12352F;
    border-radius: 6px;
}

.fitt {

    font-family: 'Inter';
    font-style: normal;
    width: 194px;
    height: 14px;
    display: inline-block;
}

.righty {
    margin-right: -70px;

}



.topbar {
    width: 1440px;
    height: 810px;

    background: #F7F7F2;
}

.boxo {
    width: 1038px;
    height: 495px;
    background: #FFFFFF;
    border-radius: 6px;

}

.siize {
    width: 155px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}



.mecha {
    box-sizing: border-box;
    width: 998px;
    height: 77px;
    border: 0.8px solid #E0E0E0;
    border-radius: 4px;
    margin-bottom: 24px;
}

.img-thumbnail {

    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sia {
    width: 194px;
    height: 14px;

}

.mech {
    width: 998px;
    height: 77px;
    border: 0.8px solid #E0E0E0;
    border-radius: 4px;
    margin: 15px;
}

.topp {
    width: 998px;
    height: 77px;


    background: #FFFFFF;
    border-radius: 6px;
}

.shift {
    margin-left: 10px;
}

.search2 {
    box-sizing: border-box;

    width: 212px;
    height: 36px;


    border: 0.8px solid #8F8F8F;
    border-radius: 4px;
}

.btn-trans {
    height: 40px;
    width: 236px;
    border: 2px black;
    border-radius: 6px solid #12352F;

}

.btn-transy {

    height: 40px;
    width: 236px;
    border: 0.8px black;
    border-radius: 6px solid #12352F;
}

.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.number-input {
    /* For Firefox */
    -moz-appearance: textfield;
}