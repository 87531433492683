* {
  margin: 0px;
  padding: 0px;
}
.main-div {
  width: 100%;
  height: 100vh;
  background: #12352f;
}
.first-box {
  background: #ffffff;
  border-radius: 25px;
  width: 100%;
  min-height: 570px;
}
.first-label {
  width: 212px;
  height: 29px;
  /* font-family: "Inter"; */
  /* font-style: normal; */
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #12352f;
}
.second-label {
  /* font-family: "Inter"; */
  /* font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3d3d3d;
}

.forget-text {
  /* width: 91px; */
  /* height: 15px; */
  /* font-family: "Inter"; */
  /* font-style: normal; */
  font-weight: 1000 !important;
  font-size: 15px  !important;
  line-height: 15px;
  text-decoration-line: underline;
  color: #7d7d7d;
}

.login-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 23px 12px 12px 16px;
  gap: 8px;
  width: 132px;
  height: 41px;
  background: #12352f;
  border-radius: 6px;
  margin-left: 9px;
}

.login-text {
  /* font-family: "Inter"; */
  /* font-style: normal; */
  font-weight: 1000 !important;
  font-size: 50px;
  color: #febf22;
}

.form-control {
  font-weight: "1000" !important;
  color: "#7D7D7D" !important;
}
.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input {
  /* For Firefox */
  -moz-appearance: textfield;
}

