#root {
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cursorPointer {
  cursor: pointer;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: white;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

/* Badge */
.badge-pill {
  display: inline-block !important;
  padding: 1px 4px !important;
  text-align: center !important;
  color: #212529 !important;
  background-color: #ffc107 !important;
  border-radius: 50%;
  top: -7px !important;
  right: -7px !important;
}

.parentContainer {
  min-height: 100vh;
}

.font-weight-bold {
  font-weight: 700!important;
}

.border-right {
  border-right: 1px solid #dee2e6!important;
}

.t-12{
  font-size: 12px !important; 
}
.t-14{
  font-size: 14px !important; 
}
.t-16{
  font-size: 16px !important; 
}
.t-32 {
  font-size: 32px !important; 
}

/* sidebar */
#sidebar {
  transition: width 0.5s linear;
}
@media only screen and (max-width: 767px) {
  #mainContainer {
    width: 95% !important;
  }
}
#mainContainer {
  transition: width 0.5s linear;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}
.sidebar_logo{
  width: 100px;
}
.user_logo {
  width: 50px !important;
  height: 50px !important;
}

.selectedCategory {
  background-color: #FFEDC2;
}
.sidebarCategoryHeight {
  height: calc(100vh - 400px);
}
.userLogo {
  width: 50px !important;
  height: 50px !important;
  object-fit: cover;
}

/* pagination */
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}
.pagination>li {
  display: inline-block;
  list-style: none;
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  color: #2c689c;
  background-color: #f8f9fa;
  outline: none;
  margin-left: -1px;
  border-radius: 5px;
  border: none;
}

.pagination>li.active>a {
  color: #fff;
  background-color: #12352F;
  border-color: #12352F;
}

.border-b-dashed {
  border-bottom: 1px dashed #A3A3A3;
}

.placeholder-light::placeholder {
  color:#A3A3A3;
  font-size: 14px;
}
.select-text-light {
  color:#A3A3A3;
  font-size: 14px;
}

.opacity0 {
  opacity: 0 !important;
}


.switch {
	position: relative;
	display: block;
	/* vertical-align: top; */
	width: 46px;
	height: 20px;
	padding: 3px;
	margin: 0 10px 10px 0;
	background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px);
	background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px);
	border-radius: 18px;
	box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
	cursor: pointer;
	box-sizing:content-box;
}
.switch-input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	box-sizing:content-box;
}
.switch-label {
	position: relative;
	display: block;
	height: inherit;
	font-size: 10px;
	text-transform: uppercase;
	background: #eceeef;
	border-radius: inherit;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
	box-sizing:content-box;
}
.switch-label:before, .switch-label:after {
	position: absolute;
	top: 50%;
	margin-top: -.5em;
	line-height: 1;
	-webkit-transition: inherit;
	-moz-transition: inherit;
	-o-transition: inherit;
	transition: inherit;
	box-sizing:content-box;
}
.switch-label:before {
	content: attr(data-off);
	right: 11px;
	color: #aaaaaa;
	text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
	content: attr(data-on);
	left: 11px;
	color: #FFFFFF;
	text-shadow: 0 1px rgba(0, 0, 0, 0.2);
	opacity: 0;
}
.switch-input:checked ~ .switch-label {
	background: #9CEA85;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label:before {
	opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
	opacity: 1;
}
.switch-handle {
	position: absolute;
	top: 4px;
	left: 4px;
	width: 18px;
	height: 18px;
	background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
	background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
	border-radius: 100%;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.switch-handle:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -6px 0 0 -6px;
	width: 12px;
	height: 12px;
	background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
	background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
	border-radius: 6px;
	box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}
.switch-input:checked ~ .switch-handle {
	left: 30px;
	box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}
.notificationScroll {
  height: calc(100vh - 200px);
  overflow-y: auto;
}
 
/* Transition
========================== */
.switch-label, .switch-handle {
	transition: All 0.3s ease;
	-webkit-transition: All 0.3s ease;
	-moz-transition: All 0.3s ease;
	-o-transition: All 0.3s ease;
}

.dark-checkbox{
  font-size: 24px !important;
}
.dark-checkbox:checked {
  background-color: #12352F !important;
  border-color: #12352f !important;
}


.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 280px;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;  z-index: 1;
  right: -20px;
  top: 20px;
  border-radius: 10px;
}
.dropdown-menu-option:hover {
 background: rgb(240, 240, 240);
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown a:hover {background-color: #ddd;}
.dropdown-show { display: inline-block !important;}

.text-sidebar-dropdown {
  color: #666666 !important;
}

@media  only screen and (max-width:600px){
  .dropdown-content{
    min-width: 255px;
    right: -120px;
  }
  .mobile-dropdown{
    right: -15px !important;
  }
}
.btn_bg{color: #12352f;}

.logout{
  cursor: pointer;
}



media querries for logout modal 
.modal_width{width: unset; padding: unset;}


@media only screen and (max-width:390px){
    .modal_width{width: 94%;}



}