.searcher {
    box-sizing: border-box;

    width: 212px;
    height: 36px;


    border: 0.8px solid #8F8F8F;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 4px;
}

.action {
    width: 88px;
    height: 36px;


    background: #12352F;
    border: 0.8px solid #12352F;
    border-radius: 4px;
    color: #FEBF22;
}

.mech {
    box-sizing: border-box;

    width: 998px;
    height: 77px;


    border: 0.8px solid #E0E0E0;
    border-radius: 4px;
}

.size {
    width: 205px;
    height: 16px;


    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #3D3D3D;

}

.ags {
    text-align: center
}

.afs {
    text-align: start;
}

.ends {
    text-align: end;
}