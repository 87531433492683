/* #contanee-adjust{
    width: 1038px;
    height: 689px;
} */

.codestart{
    justify-content: space-between;
    display: flex;
}
.rrowsheight{
    height: 50px;
    width: 368px;
}
.boxallrows{
    border: 0.8px solid #E0E0E0;
    border-radius: 4px;
}
.magerfont{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'ss01' on;
    color: #3D3D3D;
}
.Actionfont{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'ss01' on;
    color: #1E1E1E;
}
.Payingfont{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #000000;
}

.btn-pay{
    width: 109px;
    height: 30px;
    border: 1px solid #12352F;
    border-radius: 6px;
    background: #12352F;;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'ss01' on;
    color: #FEBF22;
}
.btn-cancels{
    width: 94px;
    height: 31px;
    border: 0.8px solid #E0E0E0;
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    font-feature-settings: 'ss01' on;
    color: #9D9D9D;
    margin-left: 83px;
}
#hightsmall{
    height: 50px;
}
.llongrow{
    margin-left: 30px;
}

.colbox {


    width: 350px;
    height: 170px;
    border: 2px solid grey;
    
}

@media  only screen and (max-width:600px){
    #contanee-adjust{
        width: unset;
        height: unset;
    }
    .btn-cancels{
        margin-left: unset;
    }
    .llongrow{
        margin: 0 15px;
    }
}


