
 .containerbg{
    height: calc(100vh - 120px);
}
 .rowhight{
    height: 35px;
}

#row{
    height: 65px;
}

 .center {            
    text-align: center;
  }
  
 .Uploaded {
     height: '100px'
  }
  
 .Uploadimg{
    height: 212px;
    width: 213px;
  }
  
 #btnRegister{
    background: #12352F;
    color: yellow;
    height: 48px;
    width: 212px;
  } 

  .unique{
    width: 129px;
    height: 71px;
 }

.code{
    width: 129px;
    height: 48px;
}

.Textbar{
    width: 448px;
    height: 48px;
}

.smtext{
    height: 48px;
    width: 212px;
}

.box{
    height: 154px;
    width: 448px;
}

.margin{
    margin-left: 10%;
}
.margintext{
    margin-left: 19px;
}

