.main-section {
    display: flex;
    width: 100%;
    height: 100vh;
  }
  
  .section-left {
    background-color: #ffffff;
    width: 22%;
  }
  
  .section-right {
    background-color: #f7f7f2;
    width: 78%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 3%;
  }
  
  .path-section {
    height: 10%;
  }
  
  .child-section {
    height: 88%;
  }
  
  .caterogyPageScroll {
    height: calc(100vh - 335px);
  }
  
  .categoryImageDiv {
    height: 50px !important;
    width: 50px !important;
    background-color: #F1F9F3 !important;
  }
  
  .categoryImage {
    object-fit: cover !important;
  }
  
  .homepageModifierPadding {
    padding: 2rem 2rem 2rem 6rem;
  }
  
  .homepageModifierInput {
    height: 123px;
  }
  
  .homepageModifierCategoryScroll {
    height: calc(100vh - 270px);
  }
  .homepageModifierCategoryInventoryScroll {
    height: calc(100vh - 320px);
  }
  .btncls{
    background: #12352F;;
  }
  .imgheight{
    height: 123px;
  }