.main-section {
    display: flex;
    width: 100%;
    height: 100vh;
}

.section-left {
    background-color: #ffffff;
    width: 22%;
}

.section-right {
    background-color: #f7f7f2;
    width: 78%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 3%;
}

.path-section {
    height: 10%;
}

.child-section {
    height: 88%;
}

.caterogyPageScroll {
    height: calc(100vh - 335px);
}

.categoryImageDiv {
    height: 50px !important;
    width: 50px !important;
    background-color: #F1F9F3 !important;
}

.categoryImage {
    object-fit: cover !important;
}

.deliveredSuccess {
    background: #29AE13;
    padding: 5px 15px;
    border-radius: 15px;
}

.dot:after {
    content: "";
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
}

.redDot:after {
    background: red;
}

.greenDot:after {
    background: green;
}

.referral_background {
    background-image: url("../../assets/images/referralBackground.svg");
    background-color: #12352F;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-position: top;
    /* background-position:  ; */
}

.referral_background2 {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.referralCode {
    border: 4px solid #12352F;
}

@media  only screen and (max-width:600px){
    .referral-mobile{
        width: unset;
    }
}